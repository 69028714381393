import React from 'react';
import classNames from 'classnames';
import { useAtom } from 'jotai/index';
import styles from './css/index.module.scss';
import ZoomInIcon from '../../../../assets/toolButtons/zoomIn.svg';
import ZoomOutIcon from '../../../../assets/toolButtons/zoomOut.svg';
import RotateLeftIcon from '../../../../assets/toolButtons/rotateLeft.svg';
import RotateRightIcon from '../../../../assets/toolButtons/rotateRight.svg';
import {
  gridZoomScaleAtom, objectsListAtom, schemeDotsAtom, schemeMiddleDotAtom,
} from '../../../../atoms/schemeAtoms';
import PlanServices from '../../../../services/PlanServices';

function PlanBackgroundButtons() {
  const [objectsList, setObjectsList] = useAtom(objectsListAtom);
  const [schemeDots, setSchemeDots] = useAtom(schemeDotsAtom);
  const [schemeMiddleDot, setSchemeMiddleDot] = useAtom(schemeMiddleDotAtom);
  const [gridZoomScale, setGridZoomScale] = useAtom(gridZoomScaleAtom);

  const onZoomInClick = () => {
    setGridZoomScale(1);
  };
  const onZoomOutClick = () => {
    setGridZoomScale(-1);
  };

  const onRotateClick = (side) => () => {
    const newSchemeDots = [...schemeDots].map((item) => PlanServices.rotateDot(item, schemeMiddleDot, side));
    const newObjectsList = [...objectsList].map((item) => {
      [item.fields.x, item.fields.y] = PlanServices.rotateDot([item.fields.x, item.fields.y], schemeMiddleDot, side);
      // this is for rotating 90 degrees
      // item.fields.rotation += side === 'left' ? 90 : -90;
      item.fields.rotation += 180;
      return item;
    });

    setSchemeDots(newSchemeDots);
    setObjectsList(newObjectsList);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames({
          [styles.button]: true,
          [styles.disabled]: false,
        })}
        onClick={onZoomInClick}
      >
        <img src={ZoomInIcon} alt="+" />
      </div>
      <div
        className={classNames({
          [styles.button]: true,
          [styles.disabled]: false,
        })}
        onClick={onZoomOutClick}
      >
        <img src={ZoomOutIcon} alt="-" />
      </div>
      {/* <div
        className={styles.button}
        onClick={onRotateClick('left')}
      >
        <img src={RotateLeftIcon} alt="<" />
      </div> */}
      <div
        className={styles.button}
        onClick={onRotateClick('right')}
      >
        <img src={RotateRightIcon} alt=">" />
      </div>
    </div>
  );
}

export default PlanBackgroundButtons;
