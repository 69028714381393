import { atom } from 'jotai';
import deepClone from '../helpers/deepClone';
import InfoModal from '../components/InfoModal';
import LoadingModal from '../components/LoadingModal';
import ConfirmModal from '../components/ConfirmModal';

const modalInitObj = {
  isOpen: false,
  closeFunction: undefined,
  children: null,
  style: undefined,
  closeOnPressOutside: true,
  overlayStyle: undefined,
  closeTimeoutMS: null,
};

const tokenAtom = atom('');

const currentPathNameAtom = atom('');

const isRootAtom = atom(false);

const containerRefCurrentAtom = atom(null);

const zoomOutRatioAtom = atom(1);

const windowHeightAtom = atom(0);

const modalStateAtom = atom(deepClone(modalInitObj));

const closeModalAtom = atom(null, (get, set) => {
  const newObj = { ...deepClone(modalInitObj), isOpen: false, closeTimeoutMS: get(modalStateAtom).closeTimeoutMS };
  set(modalStateAtom, newObj);
});

const openInfoModalAtom = atom(null, (get, set, val) => {
  const newObj = {
    ...get(modalStateAtom), ...val, children: <InfoModal {...val} />, isOpen: true, closeOnPressOutside: val.closeOnPressOutside ?? true,
  };

  set(modalStateAtom, newObj);
});

const openConfirmModalAtom = atom(null, (get, set, val) => {
  const newObj = {
    ...get(modalStateAtom), ...val, children: <ConfirmModal {...val} />, isOpen: true, closeOnPressOutside: val.closeOnPressOutside ?? true,
  };

  set(modalStateAtom, newObj);
});

const openLoadingModalAtom = atom(null, (get, set, val) => {
  const newObj = {
    ...get(modalStateAtom), ...val, children: <LoadingModal {...val} />, isOpen: true, closeOnPressOutside: false,
  };
  set(modalStateAtom, newObj);
});

// smaller than 1440px
const isNavBarExpandedAtom = atom(true);

const accountLoginAtom = atom('');

const reloadFnAtom = atom(null);

export {
  tokenAtom,
  windowHeightAtom,
  openLoadingModalAtom,
  containerRefCurrentAtom,
  modalStateAtom,
  closeModalAtom,
  isNavBarExpandedAtom,
  accountLoginAtom,
  openInfoModalAtom,
  isRootAtom,
  zoomOutRatioAtom,
  currentPathNameAtom,
  openConfirmModalAtom,
  reloadFnAtom,
};
