import widgetLanguages from './widgetLanguages';
import getWordForm, { WORD_FORMS } from '../../../helpers/getWordForm';

const WT = {
  person: {
    [widgetLanguages.ru.code]: (count) => getWordForm(count, WORD_FORMS.person),
    [widgetLanguages.en.code]: (count) => (count === 1 ? 'person' : 'persons'),
    [widgetLanguages.am.code]: (count) => 'մարդ',
    [widgetLanguages.kz.code]: (count) => (count === 1 ? 'адам' : 'адамдар'),
  },
  policy: {
    [widgetLanguages.ru.code]: () => 'Нажимая кнопку “Забронировать” Вы принимаете условия пользовательского соглашения и политики конфиденциальности',
    [widgetLanguages.en.code]: () => 'By clicking the “Book” button you accept the terms of the user agreement and privacy policy',
    [widgetLanguages.am.code]: () => 'Սեղմելով “Ամրագրել” կոճակը՝ դուք ընդունում եք օգտագործողի համաձայնագրի և գաղտնիության քաղաքականության պայմանները',
    [widgetLanguages.kz.code]: () => '“Брондау” түймесін басу арқылы сіз пайдаланушы келісімінің және құпиялылық саясатының шарттарын қабылдайсыз',
  },
  loading: {
    [widgetLanguages.ru.code]: () => 'Загрузка...',
    [widgetLanguages.en.code]: () => 'Loading...',
    [widgetLanguages.am.code]: () => 'Բեռնվում է...',
    [widgetLanguages.kz.code]: () => 'Жүктелуде...',
  },
  yourName: {
    [widgetLanguages.ru.code]: () => 'Ваше имя',
    [widgetLanguages.en.code]: () => 'Your name',
    [widgetLanguages.am.code]: () => 'Ձեր անունը',
    [widgetLanguages.kz.code]: () => 'Сіздің атыңыз',
  },
  yourNumber: {
    [widgetLanguages.ru.code]: () => 'Ваш телефон',
    [widgetLanguages.en.code]: () => 'Your phone number',
    [widgetLanguages.am.code]: () => 'Ձեր հեռախոսահամարը',
    [widgetLanguages.kz.code]: () => 'Сіздің телефон нөміріңіз',
  },
  namePlaceholder: {
    [widgetLanguages.ru.code]: () => 'Иван',
    [widgetLanguages.en.code]: () => 'John',
    [widgetLanguages.am.code]: () => 'Հովհաննես',
    [widgetLanguages.kz.code]: () => 'Ержан',
  },
  bookingRequests: {
    [widgetLanguages.ru.code]: () => 'Пожелания к бронированию',
    [widgetLanguages.en.code]: () => 'Booking requests',
    [widgetLanguages.am.code]: () => 'Ամրագրումների պահանջներ',
    [widgetLanguages.kz.code]: () => 'Брондау сұраныстары',
  },
  book: {
    [widgetLanguages.ru.code]: () => 'Забронировать',
    [widgetLanguages.en.code]: () => 'Book',
    [widgetLanguages.am.code]: () => 'Ամրագրել',
    [widgetLanguages.kz.code]: () => 'Брондау',
  },
  successfullyCreated: {
    [widgetLanguages.ru.code]: () => 'Ваш столик успешно забронирован, будем рады видеть вас!',
    [widgetLanguages.en.code]: () => 'Your table has been successfully booked, we look forward to seeing you!',
    [widgetLanguages.am.code]: () => 'Ձեր սեղանը հաջողությամբ ամրագրվել է, մենք ուրախ կլինենք տեսնել ձեզ!',
    [widgetLanguages.kz.code]: () => 'Сіздің үстеліңіз сәтті брондалды, біз сізді көруге қуаныштымыз!',
  },
  cancelBooking: {
    [widgetLanguages.ru.code]: () => 'Прекратить бронирование?',
    [widgetLanguages.en.code]: () => 'Cancel booking?',
    [widgetLanguages.am.code]: () => 'Դադարեցնել ամրագրումը՞',
    [widgetLanguages.kz.code]: () => 'Брондауды тоқтату керек пе?',
  },
  cancel: {
    [widgetLanguages.ru.code]: () => 'Прекратить',
    [widgetLanguages.en.code]: () => 'Cancel',
    [widgetLanguages.am.code]: () => 'Դադարեցնել',
    [widgetLanguages.kz.code]: () => 'Тоқтату',
  },
  continue: {
    [widgetLanguages.ru.code]: () => 'Продолжить',
    [widgetLanguages.en.code]: () => 'Continue',
    [widgetLanguages.am.code]: () => 'Շարունակել',
    [widgetLanguages.kz.code]: () => 'Жалғастыру',
  },
  close: {
    [widgetLanguages.ru.code]: () => 'Закрыть',
    [widgetLanguages.en.code]: () => 'Close',
    [widgetLanguages.am.code]: () => 'Փակել',
    [widgetLanguages.kz.code]: () => 'Жабу',
  },
  repeat: {
    [widgetLanguages.ru.code]: () => 'Повторить',
    [widgetLanguages.en.code]: () => 'Repeat',
    [widgetLanguages.am.code]: () => 'Կրկնել',
    [widgetLanguages.kz.code]: () => 'Қайталау',
  },
  share: {
    [widgetLanguages.ru.code]: () => 'Поделитесь с друзьями!',
    [widgetLanguages.en.code]: () => 'Share with friends!',
    [widgetLanguages.am.code]: () => 'Կիսվեք ընկերների հետ!',
    [widgetLanguages.kz.code]: () => 'Достарыңызбен бөлісіңіз!',
  },
};

export default WT;
