import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import { Navigate } from 'react-router';
import { useAtom, Provider } from 'jotai/index';
import moment from 'moment';
import ProtectedRoutes from './navigation/ProtectedRoutes';
import UnprotectedRoutes from './navigation/UnprotectedRoutes';
import LoginPage from './pages/Login';
import {
  accountLoginAtom, currentPathNameAtom, isRootAtom, reloadFnAtom, tokenAtom,
} from './atoms/globalAtoms';
import LocalStorageServices from './services/LocalStorageServices';
import sendRequest from './helpers/sendRequest';
import Api from './api';
import { addressesAtom, modulesAtom, selectedAddressAtom } from './atoms/accountAtoms';
import sleep from './helpers/sleep';
import store from './atoms';
import useNavigationBlocker from './hooks/UseNavigationBlocker';
import Widget from './pages/Widget';
import PasswordRecovery from './pages/PasswordRecovery';
import TokenGenerate from './pages/TokenGenerate';
import useBeforeUnload from './hooks/UseBeforeUnload';

function App() {
  const [isReady, setIsReady] = useState(false);

  const [token, setToken] = useAtom(tokenAtom);
  const [, setAddresses] = useAtom(addressesAtom);
  const [selectedAddress, setSelectedAddress] = useAtom(selectedAddressAtom);
  const [modules, setModules] = useAtom(modulesAtom);
  const [, setAccountLogin] = useAtom(accountLoginAtom);
  const [, setIsRoot] = useAtom(isRootAtom);
  const [currentPathName, setCurrentPathName] = useAtom(currentPathNameAtom);
  const [, setReloadFn] = useAtom(reloadFnAtom);

  const isAuthorized = Boolean(token);
  const [authorizedFirstScreen, setAuthorizedFirstScreen] = useState(modules[0]?.path || '');
  const unAuthorizedFirstScreen = '/login';

  const { reloadPage } = useBeforeUnload();

  useEffect(() => {
    const tokenFromStorage = LocalStorageServices.getToken();
    if (tokenFromStorage) {
      setToken(tokenFromStorage);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (token && currentPathName !== '/widget') {
        const data = await sendRequest({
          request: Api.init,
          warnErrorText: 'while init',
        });

        if (data?.status !== 'ok') {
          await sleep(500);
          setToken('');
          setIsReady(true);
          return;
        }

        setAddresses(data.data.addresses);
        if (!selectedAddress) {
          setSelectedAddress(data.data.addresses[0] || null);
        }
        if (data.data.root) {
          // for opening the tab from addresses
          data.data.modules.push({
            name: 'План помещений',
            sort: 3,
            icon: 'plan',
            module_name: 'plan',
            content: null,
            show: false,
          });
        }
        setModules(data.data.modules);
        setAccountLogin(data.data.login);
        setIsRoot(data.data.root);
      }

      await sleep(500);
      setIsReady(true);
    })();
  }, [token]);

  useEffect(() => {
    setCurrentPathName(authorizedFirstScreen);
  }, [authorizedFirstScreen]);

  useEffect(() => {
    if (modules?.length) {
      setAuthorizedFirstScreen(modules[0]?.path);
    }
  }, [modules]);

  useEffect(() => {
    moment.locale('ru');
  }, []);

  useEffect(() => {
    setReloadFn(() => reloadPage);
  }, [reloadPage]);

  if (!isReady) return null;

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={<UnprotectedRoutes authorizedFirstScreen={authorizedFirstScreen} hasAccess={isAuthorized} />}
          >
            <Route index element={<LoginPage />} />
          </Route>
          <Route
            path="/password-recovery-request"
            element={<UnprotectedRoutes authorizedFirstScreen={authorizedFirstScreen} hasAccess={isAuthorized} />}
          >
            <Route index element={<PasswordRecovery />} />
          </Route>
          <Route
            path="/password-recovery/:token"
            element={<UnprotectedRoutes authorizedFirstScreen={authorizedFirstScreen} hasAccess={isAuthorized} />}
          >
            <Route index element={<TokenGenerate />} />
          </Route>

          <Route path="/" element={<ProtectedRoutes hasAccess={isAuthorized} unAuthorizedFirstScreen={unAuthorizedFirstScreen} />}>
            {Object.values(modules).map((item) => (
              <Route key={item.path} path={item.path} element={item.component} />
            ))}
            <Route path="/" element={<Navigate to={authorizedFirstScreen} replace />} />
          </Route>

          {/* <Route path="/widget" element={<Widget />} /> */}
          <Route path="/" element={<Navigate to={isAuthorized ? authorizedFirstScreen : unAuthorizedFirstScreen} replace />} />
          <Route path="*" element={<Navigate to={isAuthorized ? authorizedFirstScreen : unAuthorizedFirstScreen} replace />} />
        </Routes>

      </Router>
    </Provider>
  );
}

export default App;
